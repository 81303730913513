import conf from '../conf';
import { TeamOutlined } from '@ant-design/icons';
import generalContent from '../conf/generalContent.json';
import { Page } from '../components';
import { Button, Col, Result, Row } from 'antd';
import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
import axios from 'axios';

export default () => {
  const { data, setData, loading, setLoading, requestAsync } = useAxios();
  const [syncErrorMessage, setSyncError] = useState<string>('');
  /**
   * This effect is a workaround to set loading to false
   * since useAxios sets loading to true by default
   */
  useEffect(() => {
    setLoading(false);
  }, []);

  const handleClick = async () => {
    try {
      setSyncError('');
      setData(null);
      await requestAsync(
        `${conf.import.baseURL.replace('import', 'syncs')}/odp?!odpSynced&odpSynced=false&_demoplatform_session_id`,
        'POST',
        {},
      );
    } catch (error) {
      if (axios.isAxiosError(error) && typeof error.response?.data === 'string') {
        setSyncError(error.response.data);
      } else {
        setSyncError(error instanceof Error ? error.message : 'Synchronization failed');
      }
    }
  };

  return (
    <Page
      description={generalContent.pages.ODPSynch.pageDescription}
      title={generalContent.pages.ODPSynch.title}
      titleIcon={<TeamOutlined />}
    >
      <Row gutter={[24, 0]}>
        <Col>
          <Button type='primary' disabled={loading} onClick={handleClick}>
            Synch with ODP
          </Button>
        </Col>
      </Row>

      {data && <Result title={'The synchronization was triggered'} />}
      {syncErrorMessage && <Result title={syncErrorMessage} />}
    </Page>
  );
};
